[id*="כללי-גריד"] {
  pointer-events:none
}

body {
  font-family:arial;
  margin:0px;
  direction:rtl;
  overflow:hidden;
  padding-bottom: env(safe-area-inset-bottom);
}
.l1 {
width: 100%;
/* height: 100%; */
/* height: 100vh; */
overflow: hidden;
background: skyblue;
/* display:flex; */
/* align-items:center; */
flex-grow:1;
}


.buttons {
  position: absolute;
  right:10px;
  top:10px;
  z-index: 1;
}
button {
  border:none;
  background:white;
  padding:10px 20px;
  font-size:20px;
}

.above-all{
  display:flex;
  flex-direction: column;
  height: 100svh;
}


.svgUp {
  flex-grow: 1;
}

.float-up {
  background:#3C96FF;
  color:white;
  font-size:24px;
  top:0px;
  z-index:1;
  padding:30px 20px;
}
.float {
  background:#685123;
  /* display: flex; */
  overflow:scroll;
  z-index:1;
  color:white;
  padding-top:30px;
  padding-right:30px;
  padding-left:30px;
  padding-bottom:30px;
}

.clicked {
  outline: 3px solid green;
  border: 3px solid green;
  border-radius:50%;

}

.image {
  background-size:cover!important;
  width: 60px!important;
  height: 60px!important;
  margin-top:10px;
  background-position: center!important;
}

.big-image {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  background-size: contain;
  background-repeat: no-repeat!important;
  background-position: center!important;
  background-color: rgba(0,0,0,0.9)!important;
}
.big-text {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  background-size: contain;
  background-repeat: no-repeat!important;
  background-position: center!important;
  background-color: rgba(0,0,0,0.9)!important;
  display:flex;
  color:white;
  align-items:center;
  font-size:25px;
}
.big-text > div{
  flex-grow: 1;
  text-align: right;
  white-space: break-spaces;
  padding: 0px 30px;
  line-height: 1.5;
  overflow: scroll;
}

.left-size {
  width:200px;
  height: 100vh;
  position: absolute;
  left: 0px;
  z-index: 2;
  background-color:gray;
}

.item {
  user-select: none;
  width:40px;
  height:40px;
  background:red;
  border-radius: 50%;
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; 
}

#inside-item {
  position: absolute;
}